import { AppThunk } from "../../app/store";
import axios from "axios";
import { SERVER_URL } from "../../utils/constants";
import handleAPIError from "../../utils/handleAPIError";
import { toast } from "react-toastify";
import {
    addBasicContainer,
    BasicContainer,
    basicContainersLoaded,
    basicContainersLoading,
    modifyBasicContainer,
    removeBasicContainer,
    upsertManyBasicContainers,
} from "./basicContainersSlice";

export const ApiGetBasicContainers = (): AppThunk => async (dispatch) => {
    try {
        dispatch(basicContainersLoading());
        const { data }: any = await axios.get(`${window.location.origin.toString()}/api/basiccontainer/getall`);
        dispatch(upsertManyBasicContainers(data));
        dispatch(basicContainersLoaded());
    } catch (err) {
        dispatch(basicContainersLoaded());
        handleAPIError(err, dispatch);
    }
};

export const ApiRemoveBasicContainer =
    (_id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(basicContainersLoading());
            await axios.delete(`${window.location.origin.toString()}/api/basiccontainer/delete`, {
                data: { _id },
            });

            dispatch(removeBasicContainer(_id));
            toast.success("Suppression réussie");
            dispatch(basicContainersLoaded());
        } catch (err) {
            dispatch(basicContainersLoaded());
            handleAPIError(err, dispatch);
        }
    };

export const ApiUpdateBasicContainer =
    (basicContainer: BasicContainer): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(basicContainersLoading());

            const { data } = await axios.post(`${window.location.origin.toString()}/api/basiccontainer/update`, basicContainer);
            dispatch(
                modifyBasicContainer({
                    id: basicContainer._id,
                    changes: data.basicContainer,
                })
            );
            toast.success("Modification réussie");
            dispatch(basicContainersLoaded());
        } catch (err) {
            dispatch(basicContainersLoaded());
            handleAPIError(err, dispatch);
        }
    };

export const ApiCreateBasicContainer =
    (basicContainer: BasicContainer): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(basicContainersLoading());

            const { data } = await axios.post(`${window.location.origin.toString()}/api/basiccontainer/create`, basicContainer);
            dispatch(addBasicContainer(data.basicContainer));
            toast.success("Le contenant élementaire a bien été créé");
            dispatch(basicContainersLoaded());
        } catch (err) {
            dispatch(basicContainersLoaded());
            handleAPIError(err, dispatch);
        }
    };
