import axios from "axios";
import { SERVER_URL } from "../../utils/constants";
import { toast } from "react-toastify";
import { AppThunk } from "../../app/store";
import { prestashopOrdersLoaded, prestashopOrdersLoading, removePrestashopOrder, setPrestashopOrders } from "./prestashopOrdersSlice";
import handleAPIError from "../../utils/handleAPIError";

export const ApiGetPrestashopOrders = (): AppThunk => async (dispatch) => {
    try {
        dispatch(prestashopOrdersLoading());
        const { data } = await axios.get(`${window.location.origin.toString()}/api/orderPrestashop/getall`);
        dispatch(setPrestashopOrders(data.orders));
        dispatch(prestashopOrdersLoaded());
    } catch (err) {
        dispatch(prestashopOrdersLoaded());
        handleAPIError(err, dispatch);
    }
};

export const ApiRemovePrestashopOrder =
    (_id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(prestashopOrdersLoading());
            await axios.delete(`${window.location.origin.toString()}/api/orderPrestashop/delete`, {
                data: { _id },
            });
            dispatch(removePrestashopOrder(_id));
            dispatch(prestashopOrdersLoaded());
        } catch (err) {
            dispatch(prestashopOrdersLoaded());
            handleAPIError(err, dispatch);
        }
    };

export const getInfoPOS = async (posId: string, deliveryDate: string) => {
    try {
        const { data } = await axios.get(`${window.location.origin.toString()}/api/order/getInfoPointOfSale/${posId}&${deliveryDate}`);

        toast.success("Chargement réussie");

        return data;
    } catch (err) {
        toast.error("Erreur lors de la requête !");
    }
};
