// point d'entré pour le frontend (client web)
import { CssBaseline, LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ThemeProvider, Theme, StyledEngineProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";
import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import io from "socket.io-client";
import { APIIsUserConnected } from "../features/auth/authAPI";
import { ApiGetBasicContainers } from "../features/basicContainers/basicContainersAPI";
import { ApiGetCities } from "../features/cities/citiesAPI";
import { ApiGetGlobalContainers } from "../features/globalContainers/globalContainersAPI";
import { setPhoneStatus } from "../features/openvidu/openviduSlice";
import { ApiGetPOS } from "../features/pointOfSales/posAPI";
import { ApiGetPrestashopOrders } from "../features/prestashopOrders/prestashopOrdersAPI";
import { ApiGetSocleoOrders } from "../features/socleoOrders/socleoOrdersAPI";
import TrailerMap from "../features/trailers/TrailerMap";
import { ApiGetTrailers } from "../features/trailers/trailersAPI";
import { modifyTrailer, Trailer } from "../features/trailers/trailersSlice";
import { ApiGetUsers } from "../features/users/usersAPI";
import AppBar from "../layouts/AppBar";
import Drawer from "../layouts/Drawer";
import { useAudio } from "../utils/hooks";
import "./App.css";
import { RootState } from "./rootReducer";
import store from "./store";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

const LoginPage = lazy(() => import("../features/auth/LoginPage"));
const ForgotPasswordPage = lazy(() => import("../features/auth/ForgotPasswordPage"));
const ChangePasswordPage = lazy(() => import("../features/auth/ChangePasswordPage"));
const PrestashopOrdersPage = lazy(() => import("../features/prestashopOrders/PrestashopOrdersPage"));
const SocleoOrdersPage = lazy(() => import("../features/socleoOrders/SocleoOrdersPage"));
const TrailersPage = lazy(() => import("../features/trailers/TrailersPage"));
const TrailersStatePage = lazy(() => import("../features/trailers/TrailersStatePage"));
const TrailersControlCenterPage = lazy(() => import("../features/trailers/TrailersControlCenterPage"));
// const OpenViduPage = lazy(
//   () => import("../features/openvidu/OpenViduPageOld.js")
// );
const OpenViduCallPage = lazy(() => import("../features/openvidu/OpenViduPage"));
const CitiesPage = lazy(() => import("../features/cities/CitiesPage"));
const POSPage = lazy(() => import("../features/pointOfSales/POSPage"));
const ImportFilePage = lazy(() => import("../features/import/ImportFilePage"));
const PlanningPage = lazy(() => import("../features/planning/PlanningPage"));
const UsersAdminPage = lazy(() => import("../features/users/UsersAdminPage"));
const UserDashboard = lazy(() => import("../features/users/UserDashboard"));
const PrivateRoute = lazy(() => import("../components/PrivateRoute"));
const WelcomePage = lazy(() => import("../components/WelcomePage"));
const ExportFilePage = lazy(() => import("../components/ExportFilePage"));
const ProductsPage = lazy(() => import("../features/products/ProductsPage"));
const BasicContainersPage = lazy(() => import("../features/basicContainers/BasicContainersPage"));
const GlobalContainersPage = lazy(() => import("../features/globalContainers/GlobalContainersPage"));
const EutecticPlates = lazy(() => import("../features/eutecticPlates/EutecticPlatesPage"));

const muiTheme = responsiveFontSizes(
    createTheme({
        components: {
            MuiTextField: {
                defaultProps: {
                    variant: "standard",
                },
            },
            MuiContainer: {
                defaultProps: {
                    maxWidth: "xl",
                },
            },
            MuiSelect: {
                defaultProps: {
                    variant: "standard",
                },
            },
            MuiInputLabel: {
                defaultProps: {
                    variant: "standard",
                },
            },
        },
        /* your custom theme */
    })
);

const useStyles = makeStyles((theme: any) => ({
    root: {
        display: "flex",
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
    },
}));

/**
 * App component containing the router to every page/component of the application
 * there is also the Appbar and the drawers that contains navigation links
 */
const App: React.FC = () => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
                <Root></Root>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

const Root: React.FC = () => {
    const classes = useStyles();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = useCallback(() => {
        setIsDrawerOpen((d) => !d);
    }, []);

    const openDrawer = useCallback(() => {
        setIsDrawerOpen(true);
    }, []);

    const closeDrawer = useCallback(() => {
        setIsDrawerOpen(false);
    }, []);

    const history = useHistory();
    const dispatch = useDispatch();
    const openviduStatus = useSelector((state: RootState) => state.openvidu.status);

    const [playing, play, pause] = useAudio("../assets/ring.mp3");

    // Connect to socket on mount only
    const socket = useMemo(
        // () => io("https://dev.logx.vraipluslocal.fr", { path: "/api/socket.io" }),
        () =>
            io(window.location.origin.toString() || "", {
                path: process.env.REACT_APP_SOCKET_IO_PATH,
            }),
        // io(process.env.REACT_APP_SOCKET_IO_URL || "", {
        //     path: process.env.REACT_APP_SOCKET_IO_PATH,
        // }),
        []
    );

    useEffect(() => {
        if (playing) {
            toast.warn("CA SONNE !", {
                autoClose: false,
                onClose: () => {
                    dispatch(setPhoneStatus("pending"));
                    history.push("/video");
                },
            });
        }
    }, [dispatch, playing, history]);

    // Handles socket io messages about DB changes
    const handleMongoChangeStream = useCallback(
        (data) => {
            switch (data.operationType) {
                case "update":
                    const id: string = data.documentKey._id;
                    const { updatedFields } = data.updateDescription;
                    const trailer = store.getState().trailers.list.find((t: Trailer) => t._id === id);
                    dispatch(modifyTrailer({ ...trailer, ...updatedFields }));
            }
        },
        [dispatch]
    );

    // Handles every socket io events
    useEffect(() => {
        socket.on("connect", () => {
            console.log("connecté à socket.io");
            socket.emit("clientLogX");
        });
        socket.on("trailerData", (data: any) => handleMongoChangeStream(data));

        socket.on("faisClaquerLesWatts", (data: any) => {
            if (openviduStatus === "pending") {
                dispatch(setPhoneStatus("ringing"));
            }
        });
    }, [socket, openviduStatus, dispatch, handleMongoChangeStream]);

    useEffect(() => {
        if (openviduStatus === "ringing") {
            play();
        } else {
            pause();
        }
    }, [openviduStatus, play, pause]);

    // Disconnect from socket on dismount only
    useEffect(() => {
        return () => {
            socket.disconnect();
        };
    }, [socket]);

    useEffect(() => {
        dispatch(ApiGetCities());
        dispatch(ApiGetUsers());
        dispatch(ApiGetPrestashopOrders());
        dispatch(ApiGetSocleoOrders());
        dispatch(ApiGetPOS());
        dispatch(ApiGetTrailers());
        dispatch(APIIsUserConnected());
        dispatch(ApiGetBasicContainers());
        dispatch(ApiGetGlobalContainers());
    }, [dispatch]);
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar toggleDrawer={toggleDrawer} />
            <Drawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} openDrawer={openDrawer} closeDrawer={closeDrawer} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Suspense fallback={<LinearProgress />}>
                    <Switch>
                        <Route exact path="/">
                            <WelcomePage />
                        </Route>
                        <PrivateRoute path="/map">
                            <TrailerMap />
                        </PrivateRoute>
                        <Route path="/login">
                            <LoginPage />
                        </Route>
                        <Route path="/forgotPassword">
                            <ForgotPasswordPage />
                        </Route>
                        <Route path="/changePassword/:token">
                            <ChangePasswordPage />
                        </Route>
                        <Route path="/signup">
                            <Redirect to="/usersadmin" />
                        </Route>
                        <PrivateRoute path="/prestashopOrders">
                            <PrestashopOrdersPage />
                        </PrivateRoute>
                        <PrivateRoute path="/socleoOrders">
                            <SocleoOrdersPage />
                        </PrivateRoute>
                        <PrivateRoute path="/video">
                            <OpenViduCallPage />
                        </PrivateRoute>
                        <PrivateRoute path="/cities">
                            <CitiesPage />
                        </PrivateRoute>
                        <PrivateRoute path="/pos/:cityId">
                            <POSPage />
                        </PrivateRoute>
                        <PrivateRoute path="/trailer/:city&:postcode/:posId">
                            <TrailersPage />
                        </PrivateRoute>
                        <PrivateRoute path="/trailers">
                            <TrailersPage />
                        </PrivateRoute>
                        <PrivateRoute path="/trailersstate">
                            <TrailersStatePage />
                        </PrivateRoute>
                        <PrivateRoute path="/trailerscontrol">
                            <TrailersControlCenterPage />
                        </PrivateRoute>
                        <PrivateRoute path="/import">
                            <ImportFilePage />
                        </PrivateRoute>
                        <PrivateRoute path="/exports">
                            <ExportFilePage />
                        </PrivateRoute>
                        <PrivateRoute path="/planning">
                            <PlanningPage />
                        </PrivateRoute>
                        <PrivateRoute path="/usersadmin">
                            <UsersAdminPage />
                        </PrivateRoute>
                        <PrivateRoute path="/userdashboard">
                            <UserDashboard />
                        </PrivateRoute>
                        <PrivateRoute path="/products">
                            <ProductsPage />
                        </PrivateRoute>
                        <PrivateRoute path="/basiccontainers">
                            <BasicContainersPage />
                        </PrivateRoute>
                        <PrivateRoute path="/globalcontainers">
                            <GlobalContainersPage />
                        </PrivateRoute>
                        <PrivateRoute path="/eutecticplates">
                            <EutecticPlates />
                        </PrivateRoute>
                    </Switch>
                </Suspense>
            </main>
            {/* Container for the toast messages */}
            <ToastContainer position={"bottom-center"} autoClose={3000} newestOnTop />
        </div>
    );
};

export default App;
