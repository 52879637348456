import { AppThunk } from "../../app/store";
import axios, { AxiosError } from "axios";
import { SERVER_URL } from "../../utils/constants";
import {
    PlanningEntry,
    planningLoaded,
    planningLoading,
    removePlanningEntries,
    modifyPlanningEntry,
    addPlanningEntries,
    setUnavailableTransporters,
    upsertManyPlanning,
} from "./planningSlice";
import handleAPIError from "../../utils/handleAPIError";
import { toast } from "react-toastify";

export const ApiGetPlanning = (): AppThunk => async (dispatch) => {
    try {
        dispatch(planningLoading());
        const { data }: any = await axios.get(`${window.location.origin.toString()}/api/planning/getall`);
        dispatch(planningLoaded());
        dispatch(upsertManyPlanning(data));
    } catch (err) {
        dispatch(planningLoaded());
        handleAPIError(err, dispatch);
    }
};

export const ApiGetPlanningFromWeekYearAndCity =
    (cityId: string, year: number, weekNumber: number): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(planningLoading());
            const { data }: any = await axios.get(
                `${window.location.origin.toString()}/api/planning/getByWeekNumberAndYear/${weekNumber}&${year}&${cityId}`
            );
            dispatch(upsertManyPlanning(data.findPlannings));
            dispatch(setUnavailableTransporters(data.findTransportersAndTrailers));
            dispatch(planningLoaded());
        } catch (err) {
            dispatch(planningLoaded());
            handleAPIError(err, dispatch);
        }
    };
export const ApiGetPlanningByMonthFromDate =
    (date: any): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(planningLoading());
            const { data }: any = await axios.get(`${window.location.origin.toString()}/api/planning/getByMonthFromDate/${date}`);
            dispatch(upsertManyPlanning(data.findPlannings));
            dispatch(planningLoaded());
            return data.findPlannings;
        } catch (err) {
            dispatch(planningLoaded());
            handleAPIError(err, dispatch);
        }
    };
export const ApiGetPlanningByDayFromDate =
    (date: any): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(planningLoading());
            const { data }: any = await axios.get(`${window.location.origin.toString()}/api/planning/getByDayFromDate/${date}`);
            dispatch(upsertManyPlanning(data.findPlannings));
            dispatch(planningLoaded());
            return data.findPlannings;
        } catch (err) {
            dispatch(planningLoaded());
            handleAPIError(err, dispatch);
        }
    };

export const ApiGetLastFromTrailerId = (trailerId) => async () => {
    try {
        const { data }: any = await axios.get(`${window.location.origin.toString()}/api/planning/getLastFromTrailerId/${trailerId}`);
        return data;
    } catch (err) {}
};

export const ApiGetLocationFromTrailerIdAndDate = (trailerId: string | undefined, startDate: Date, endDate: Date) => async () => {
    try {
        const { data }: any = await axios.get(`${window.location.origin.toString()}/api/locations/`, {
            params: {
                trailerId,
                startDate,
                endDate,
            },
        });
        return data;
    } catch (err) {}
};

export const ApiRemovePlanningEntry =
    (_id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(planningLoading());
            const { data } = await axios.delete(`${window.location.origin.toString()}/api/planning/delete`, {
                data: { _id },
            });

            dispatch(removePlanningEntries(data.planning));
            toast.success("Suppression réussie");
            dispatch(setUnavailableTransporters(data.findTransportersAndTrailers));
            dispatch(planningLoaded());
        } catch (err) {
            dispatch(planningLoaded());
            handleAPIError(err, dispatch);
        }
    };

export const ApiUpdatePlanningEntry =
    (planningEntry: PlanningEntry): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(planningLoading());

            const { data } = await axios.post(`${window.location.origin.toString()}/api/planning/update`, planningEntry);
            data.planning.forEach((p: PlanningEntry) => {
                dispatch(
                    modifyPlanningEntry({
                        id: p._id,
                        changes: p,
                    })
                );
            });
            toast.success("Modification réussie");
            dispatch(setUnavailableTransporters(data.findTransportersAndTrailers));
            dispatch(planningLoaded());
        } catch (err) {
            dispatch(planningLoaded());
            handleAPIError(err, dispatch);
        }
    };

export const ApiCreatePlanningEntry =
    (planningEntry: PlanningEntry, cityId: string, createOther: boolean): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(planningLoading());

            const body = {
                planning: { ...planningEntry, city: cityId },
                createOtherPlanning: createOther,
            };

            const { data } = await axios.post(`${window.location.origin.toString()}/api/planning/create`, body);
            dispatch(addPlanningEntries(data.planning));
            toast.success("Entrée de planning créée");
            dispatch(setUnavailableTransporters(data.findTransportersAndTrailers));
            dispatch(planningLoaded());
        } catch (err) {
            dispatch(planningLoaded());
            handleAPIError(err, dispatch);
        }
    };

export const ApiGetDailyPlanning = async (date: string) => {
    try {
        const response = await axios.get(`${window.location.origin.toString()}/api/planning/downloadPlanningSummary/${date}`, {
            responseType: "blob",
        });
        const filename = response.headers["content-disposition"].split('"')[1];

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    } catch (err: AxiosError | any) {
        console.error(err.response);
        if (err.response) {
            toast.error(err.response.data.msg);
        } else {
            toast.error("Erreur de lors de la requête");
        }
    }
};
