import React from "react";
import { Drawer, SwipeableDrawer, List, ListItem, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory, useLocation } from "react-router-dom";
import DrawerList from "../components/DrawerList";

import { routes } from "../routes";
import { drawerWidth } from "../utils/constants";

const useStyles = makeStyles((theme: any) => ({
    drawer: {
        [theme.breakpoints.up("lg")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        // width: drawerWidth,
        // "&::-webkit-scrollbar": {
        //   width: "0.4em",
        // },
        // "&::-webkit-scrollbar-track": {
        //   boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        //   webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        // },
        // "&::-webkit-scrollbar-thumb": {
        //   backgroundColor: "rgba(0,0,0,.1)",
        //   outline: "1px solid rgba(0,0,0,.1)",
        // },
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

type DrawerProps = {
    isOpen: boolean;
    toggleDrawer: () => void;
    openDrawer: () => void;
    closeDrawer: () => void;
};

/**
 * Drawer component visible everywhere in the app it is toggleable from the App Appbar
 * @param isOpen is the drawer open
 * @param toggleDrawer toggles the drawer state
 */
export default function MaterialDrawer({ isOpen, toggleDrawer, openDrawer, closeDrawer }: DrawerProps) {
    const classes = useStyles();
    const history = useHistory();
    let location = useLocation();

    return (
        <nav className={classes.drawer}>
            <div className={classes.toolbar} />
            <SwipeableDrawer
                anchor="left"
                variant="temporary"
                open={isOpen}
                onClose={closeDrawer}
                onOpen={openDrawer}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 2,
                    width: drawerWidth,
                }}
            >
                <List
                    sx={{
                        width: drawerWidth,
                    }}
                >
                    {routes.map((routeObject) => {
                        if (routeObject.type === "multiple") {
                            const { items, menuTitle } = routeObject;
                            return <DrawerList key={menuTitle} items={items} menuTitle={menuTitle} closeDrawer={closeDrawer} />;
                        } else {
                            const { url, name } = routeObject;
                            return (
                                <React.Fragment key={"mobile" + url}>
                                    <ListItem
                                        button
                                        selected={location.pathname === url}
                                        onClick={() => {
                                            if (url === "/api/doc/") {
                                                document.location.href = "https://dev.logx.vraipluslocal.fr/api/doc/";
                                            }
                                            history.push(url);
                                            closeDrawer();
                                        }}
                                    >
                                        <ListItemText primary={name} />
                                    </ListItem>
                                </React.Fragment>
                            );
                        }
                    })}
                </List>
            </SwipeableDrawer>
            <Drawer
                classes={{
                    paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
                sx={{
                    display: { xs: "none", lg: "block" },
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: drawerWidth,
                        boxSizing: "border-box",
                        position: "relative",
                    },
                }}
            >
                <List>
                    {routes.map((routeObject) => {
                        if (routeObject.type === "multiple") {
                            const { items, menuTitle } = routeObject;
                            return <DrawerList key={menuTitle} items={items} menuTitle={menuTitle} closeDrawer={closeDrawer} />;
                        } else {
                            const { url, name } = routeObject;
                            return (
                                <React.Fragment key={"permanent" + url}>
                                    <ListItem
                                        button
                                        selected={location.pathname === url}
                                        onClick={() => {
                                            if (url === "/api/doc/") {
                                                document.location.href = "https://dev.logx.vraipluslocal.fr/api/doc/";
                                            }
                                            history.push(url);
                                            closeDrawer();
                                        }}
                                    >
                                        <ListItemText primary={name} />
                                    </ListItem>
                                </React.Fragment>
                            );
                        }
                    })}
                </List>
            </Drawer>
        </nav>
    );
}
