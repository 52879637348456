import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { AppThunk } from "../../app/store";
import { SERVER_URL } from "../../utils/constants";
import handleAPIError from "../../utils/handleAPIError";
import {
    removeSocleoOrder,
    setTrailersId,
    socleoOrdersLoaded,
    socleoOrdersLoading,
    upsertManySocleoOrders,
    upsertOneSocleoOrder,
} from "./socleoOrdersSlice";

export const ApiGetSocleoOrders = (): AppThunk => async (dispatch) => {
    try {
        dispatch(socleoOrdersLoading());
        const { data } = await axios.get(`${window.location.origin.toString()}/api/orderSocleo/getall`);
        dispatch(upsertManySocleoOrders(data.orders));
        dispatch(socleoOrdersLoaded());
    } catch (err) {
        dispatch(socleoOrdersLoaded());
        handleAPIError(err, dispatch);
    }
};

export const ApiRemoveSocleoOrder =
    (_id: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(socleoOrdersLoading());
            await axios.delete(`${window.location.origin.toString()}/api/orderSocleo/delete`, {
                data: { _id },
            });
            dispatch(removeSocleoOrder(_id));
            dispatch(socleoOrdersLoaded());
        } catch (err) {
            dispatch(socleoOrdersLoaded());
            handleAPIError(err, dispatch);
        }
    };

export const ApiGetSocleoOrdersByPOSAndDeliveryDate =
    (pos: string, date: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(socleoOrdersLoading());
            const { data } = await axios.get(`${window.location.origin.toString()}/api/orderSocleo/getByPointOfSaleAndDeliverydate/${pos}&${date}`);
            dispatch(upsertManySocleoOrders(data.orders));
            dispatch(setTrailersId(data.trailersId));
            dispatch(socleoOrdersLoaded());
        } catch (err) {
            dispatch(socleoOrdersLoaded());
            handleAPIError(err, dispatch);
        }
    };

export const ApiCreateSocleoOrder =
    (createData: any): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(socleoOrdersLoading());
            const { data } = await axios.post(`${window.location.origin.toString()}/api/orderSocleo/create`, createData);
            dispatch(upsertOneSocleoOrder(data.order));
            dispatch(socleoOrdersLoaded());
            toast.success("Création réussie");
        } catch (err) {
            dispatch(socleoOrdersLoaded());
            handleAPIError(err, dispatch);
        }
    };

export const ApiUpdateSocleoOrder =
    (updateData): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(socleoOrdersLoading());
            const { data } = await axios.post(`${window.location.origin.toString()}/api/orderSocleo/update`, updateData);
            dispatch(upsertOneSocleoOrder(data.order));
            dispatch(socleoOrdersLoaded());
            toast.success("Modification réussie");
        } catch (err) {
            dispatch(socleoOrdersLoaded());
            handleAPIError(err, dispatch);
        }
    };

export const ApiRemoveLockerFromSocleoOrder =
    (deleteData): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(socleoOrdersLoading());
            const { data } = await axios.post(`${window.location.origin.toString()}/api/orderSocleo/deleteLocker`, deleteData);
            dispatch(upsertOneSocleoOrder(data.order));
            dispatch(socleoOrdersLoaded());
            toast.success("Suppression réussie");
        } catch (err) {
            dispatch(socleoOrdersLoaded());
            handleAPIError(err, dispatch);
        }
    };
export const ApiOpenLocker =
    (lockerToOpen): AppThunk =>
    async (dispatch) => {
        try {
            await axios.post(`${window.location.origin.toString()}/api/orderSocleo/openLocker`, lockerToOpen);
            toast.success("Demande d'ouverture envoyée !");
        } catch (err) {
            handleAPIError(err, dispatch);
        }
    };

export const ApiInvertSocleoOrdersLockers =
    (invertData): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(socleoOrdersLoading());
            const { data } = await axios.post(`${window.location.origin.toString()}/api/orderSocleo/invertLockers`, invertData);
            dispatch(upsertManySocleoOrders(data.orders));
            dispatch(socleoOrdersLoaded());
        } catch (err) {
            dispatch(socleoOrdersLoaded());
            handleAPIError(err, dispatch);
        }
    };

export const ApiSendSocleoOrdersSms =
    (pointOfSaleId: string, deliveryDate: string): AppThunk =>
    async (dispatch) => {
        console.log("oui");
        try {
            dispatch(socleoOrdersLoading());
            // const { data } = await axios.post(`${window.location.origin.toString()}/api/orderSocleo/sendSms`);
            const { data } = await axios.post(`${window.location.origin.toString()}/api/orderSocleo/sendSms`, {
                pointOfSaleId,
                deliveryDate,
            });
            toast.success(data.msg);
            dispatch(socleoOrdersLoaded());
        } catch (err) {
            dispatch(socleoOrdersLoaded());
            handleAPIError(err, dispatch);
        }
    };

export const ApiDownloadDailyPosSummary = async (posId: string, deliveryDate: string) => {
    try {
        const response = await axios.get(`${window.location.origin.toString()}/api/orderSocleo/downloadOrdersSummary/${posId}&${deliveryDate}`, {
            responseType: "blob",
        });

        const filename = response.headers["content-disposition"].split('"')[1];

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    } catch (err) {
        toast.error("Erreur lors du téléchargement");
    }
};

export const ApiDownloadContainerSummary = async (date: string) => {
    try {
        const response = await axios.get(`${window.location.origin.toString()}/api/orderSocleo/downloadContainerSummary/${date}`, {
            responseType: "blob",
        });

        const filename = response.headers["content-disposition"].split('"')[1];

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    } catch (err: AxiosError | any) {
        console.error(err.response);
        if (err.response) {
            toast.error(err.response.data.msg);
        } else {
            toast.error("Erreur de lors de la requête");
        }
    }
};

export const ApiDownloadTrailerSummary = async (date: string) => {
    try {
        const response = await axios.get(`${window.location.origin.toString()}/api/orderSocleo/downloadTrailerSummary/${date}`, {
            responseType: "blob",
        });

        const filename = response.headers["content-disposition"].split('"')[1];

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    } catch (err: AxiosError | any) {
        console.error(err.response);
        if (err.response) {
            toast.error(err.response.data.msg);
        } else {
            toast.error("Erreur de lors de la requête");
        }
    }
};

export const ApiDownloadConditioningSummary = async (deliveryDate: string) => {
    try {
        const response = await axios.get(`${window.location.origin.toString()}/api/orderSocleo/downloadContionningToDoSummaryV2/${deliveryDate}`, {
            responseType: "blob",
        });

        const filename = response.headers["content-disposition"].split('"')[1];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    } catch (err: AxiosError | any) {
        console.error(err.response);
        if (err.response) {
            toast.error(err.response.data.msg);
        } else {
            toast.error("Erreur de lors de la requête");
        }
    }
};

export const ApiDownloadClientsLockers = async (deliveryDate: string) => {
    try {
        const response = await axios.get(`${window.location.origin.toString()}/api/orderSocleo/downloadClientsLocker/${deliveryDate}`, {
            responseType: "blob",
        });

        const filename = response.headers["content-disposition"].split('"')[1];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    } catch (err: AxiosError | any) {
        console.error(err.response);
        if (err.response) {
            toast.error(err.response.data.msg);
        } else {
            toast.error("Erreur de lors de la requête");
        }
    }
};
export const ApiDownloadLaurentLhenaff = async (deliveryDate: string) => {
    try {
        const response = await axios.get(`${window.location.origin.toString()}/api/orderSocleo/downloadLaurentLhenaff/${deliveryDate}`, {
            responseType: "blob",
        });

        const filename = response.headers["content-disposition"].split('"')[1];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    } catch (err: AxiosError | any) {
        console.error(err.response);
        if (err.response) {
            toast.error(err.response.data.msg);
        } else {
            toast.error("Erreur de lors de la requête");
        }
    }
};
export const ApiDownloadOrdersCsv = async (deliveryDate: string) => {
    try {
        const response = await axios.get(`${window.location.origin.toString()}/api/orderSocleo/downloadOrdersCsv/${deliveryDate}`, {
            responseType: "blob",
        });

        const filename = response.headers["content-disposition"].split('"')[1];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    } catch (err: AxiosError | any) {
        console.error(err.response);
        if (err.response) {
            toast.error(err.response.data.msg);
        } else {
            toast.error("Erreur de lors de la requête");
        }
    }
};
