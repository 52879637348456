// eslint-disable-next-line no-unused-vars
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { AppThunk } from "../../app/store";
import { SERVER_URL } from "../../utils/constants";
import handleAPIError from "../../utils/handleAPIError";
import { returnError } from "../error/errorSlice";
import { userLoading, loginSuccess, userLoaded, registerSuccess, loginFailed, logoutSuccess } from "./authSlice";

export const authUser =
    (email: string, password: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(userLoading());
            const { data }: any = await axios.post(
                `${window.location.origin.toString()}/api/user/login`,
                { email, password },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );
            dispatch(loginSuccess(data));
        } catch (err: AxiosError | any) {
            if (err.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
                const { data, status } = err.response;
                dispatch(returnError({ data, status }));
                dispatch(userLoaded());
            } else if (err.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log(err.request);
                dispatch(returnError({ data: { msg: err.message } }));
                dispatch(userLoaded());
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log("err", err.message);
                dispatch(returnError({ data: err.message }));
                dispatch(userLoaded());
            }
            console.error(err);
        }
    };

export const registerUser =
    (email: string, password: string, firstname: string, lastname: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(userLoading());
            const { data }: any = await axios.post(
                `${window.location.origin.toString()}/api/user/signup`,
                { email, password, firstname, lastname },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            dispatch(userLoaded());
            dispatch(registerSuccess(data));
        } catch (err: AxiosError | any) {
            if (err.response) {
                /*
                 * The request was made and the server responded with a
                 * status code that falls out of the range of 2xx
                 */
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
                const { data, status } = err.response;
                dispatch(returnError({ data, status }));
                dispatch(userLoaded());
            } else if (err.request) {
                /*
                 * The request was made but no response was received, `error.request`
                 * is an instance of XMLHttpRequest in the browser and an instance
                 * of http.ClientRequest in Node.js
                 */
                console.log(err.request);
                dispatch(returnError({ data: { msg: err.message } }));
                dispatch(userLoaded());
            } else {
                // Something happened in setting up the request and triggered an Error
                console.log("err", err.message);
                dispatch(returnError({ data: err.message }));
                dispatch(userLoaded());
            }
            console.error(err);
        }
    };

export const APIIsUserConnected = (): AppThunk => async (dispatch) => {
    try {
        dispatch(userLoading());
        const { data } = await axios.get(`${window.location.origin.toString()}/api/user/isConnected`);
        dispatch(loginSuccess(data));
    } catch (err) {
        dispatch(loginFailed());
    }
};

export const APIDisconnectUser = (): AppThunk => async (dispatch) => {
    try {
        await axios.get(`${window.location.origin.toString()}/api/user/disconnect`);
        dispatch(logoutSuccess());
    } catch (err) {
        dispatch(logoutSuccess());
    }
};

export const APIForgotPassword =
    (email: string): AppThunk =>
    async (dispatch) => {
        try {
            await axios.post(`${window.location.origin.toString()}/api/user/forgotPassword`, { email });
            toast.info(`Email de récupération envoyé à l'adresse ${email}`);
        } catch (err) {
            handleAPIError(err, dispatch);
        }
    };

export const APIChangePasswordEmail =
    (token: string, password: string): AppThunk =>
    async (dispatch) => {
        try {
            const { data } = await axios.post(`${window.location.origin.toString()}/api/user/changePasswordEmail`, {
                token,
                newPassword: password,
            });
            dispatch(loginSuccess(data.user));
            toast.success("Modification du mot de passe réussie");
        } catch (err) {
            handleAPIError(err, dispatch);
        }
    };
